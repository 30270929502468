<template>
  <v-form class="promotion-form mb-8" v-model="is_form_valid">
    <div class="bordered pa-8 mb-4">
      <p class="text-h4 font-weight-bold text-center">
        {{
          is_editing
            ? `Edit ${promotion.name}`
            : `Create New Showcase Campaign for ${selected_brand.name}`
        }}
      </p>
      <v-text-field
        v-model="promotion.name"
        autocomplete="new-password"
        label="Name"
        class="required"
        required
      />
      <v-select
        v-model="promotion.promotion_type"
        :items="PROMOTION_TYPES"
        class="required"
        :disabled="true"
        label="Promotion Type"
        :rules="[field_req]"
        required
      ></v-select>
    </div>
    <template v-if="promotion.promotion_type === 'shop'">
      <div class="bordered pa-8 mb-4">
        <p class="text-h4 font-weight-bold text-center">Promoted Shop</p>
        <v-autocomplete
          v-model="promotion.shop_id"
          :loading="loading_shops"
          :items="shops"
          item-text="name"
          item-value="id"
          :search-input.sync="search_shop"
          :rules="[field_req]"
          cache-items
          required
          class="required"
          label="Promoted Shop"
        >
          <template slot="selection" slot-scope="data">
            {{ data.item.id }}
          </template>
        </v-autocomplete>
        <v-select
          v-model="promotion.promoted_shop_layout"
          :items="SHOP_LAYOUTS"
          class="required"
          label="Shop Layout"
          :rules="[field_req]"
          required
        ></v-select>
        <template v-if="promotion.promoted_shop_layout === 'simple'">
          <p class="mb-0 font-italic font-weight-bold">Example</p>
          <img
            :src="require('@/assets/images/simple-shop-layout-example.jpg')"
            style="max-width: 100%"
            alt="Simple shop layout example"
          />
          <v-checkbox
            v-model="promotion.add_shop_page_banner"
            label="Add promotion banner on shop page"
          ></v-checkbox>
          <v-text-field
            v-model="promotion.shop_page_banner_text"
            v-if="promotion.add_shop_page_banner"
            autocomplete="new-password"
            label="Shop page banner text"
            class="required"
            required
          />
        </template>
        <template v-if="promotion.promoted_shop_layout === 'default'">
          <p class="mb-0 font-italic font-weight-bold">Example</p>
          <img
            :src="require('@/assets/images/default-shop-example.jpg')"
            style="max-width: 100%"
            alt="Simple shop layout example"
          />
        </template>
      </div>
    </template>

    <div class="bordered pa-8 mb-4">
      <p class="text-h4 font-weight-bold text-center">Showcase Style</p>
      <v-checkbox
        v-model="promotion.add_home_page_banner_top"
        label="Add banner on top of brand home page"
      ></v-checkbox>
      <p class="mb-0 font-italic font-weight-bold">Example</p>
      <img
        :src="require('@/assets/images/promotion-home-page-banner-example.jpg')"
        style="max-width: 100%"
        alt="Home page banner example"
      />
      <v-text-field
        v-model="promotion.home_page_banner_top_text"
        v-if="promotion.add_home_page_banner_top"
        autocomplete="new-password"
        label="Home page banner text"
        class="required"
        required
      />
      <v-text-field
        v-model="promotion.home_page_banner_top_call_to_action_text"
        v-if="promotion.add_home_page_banner_top"
        autocomplete="new-password"
        label="Home page banner call-to-action text"
        class="required"
        required
      />
      <v-divider height="1" class="my-4" />
      <v-checkbox
        v-model="promotion.add_navigation_element"
        label="Add navigation element to brand website"
      ></v-checkbox>
      <p class="mb-0 font-italic font-weight-bold">Example</p>
      <img
        :src="require('@/assets/images/promotion-nav-element.jpg')"
        style="max-width: 100%"
        alt="Nav element example"
      />
      <v-text-field
        v-model="promotion.navigation_element_text"
        v-if="promotion.add_navigation_element"
        autocomplete="new-password"
        label="Navigation element text"
        class="required"
        required
      />
    </div>
    <v-btn
      block
      rounded
      class="mr-0"
      color="primary"
      :disabled="!can_add_promotion"
      @click="upsert_promotion"
    >
      <v-progress-circular
        v-if="processing_promotion"
        size="25"
        color="primary"
        indeterminate
      />
      <span v-else>{{
        is_editing ? "Update Promotion" : "Add New Promotion"
      }}</span></v-btn
    >
  </v-form>
</template>

<script>
import { get_shops, upsert_promotion, get_promotion_by_id } from "@/requests";
import { field_req } from "@/utils/form_val_rules";
import { mapActions, mapState } from "vuex";

const PROMOTION_TYPES = [
  { text: "Promote Single Shop", value: "shop" },
  // { text: "Other", value: "other" },
];
const SHOP_LAYOUTS = [
  { text: "Default Shop Layout", value: "default" },
  { text: "Simple Shop Layout", value: "simple" },
];

let timeout = null;

export default {
  name: "PromotionForm",
  components: {},
  data() {
    return {
      PROMOTION_TYPES,
      SHOP_LAYOUTS,
      field_req,
      processing_promotion: false,
      loading_shops: false,
      is_form_valid: false,
      search_shop: "",
      shops: [],
      promotion: {
        name: "",
        shop_id: null,
        promotion_type: "shop",
        add_navigation_element: false,
        add_home_page_banner_top: false,
        add_shop_page_banner: false,
      },
    };
  },
  computed: {
    ...mapState("SuperAdminStore", ["selected_brand", "brands"]),
    ...mapState("UserStore", ["is_community_admin"]),
    is_editing() {
      return Boolean(this.$route?.query?.id ?? false);
    },
    can_add_promotion() {
      return this.is_form_valid;
    },
  },
  watch: {
    async search_shop(val) {
      if (!val) return;
      if (timeout) clearTimeout(timeout);

      timeout = setTimeout(async () => {
        await this.search_shops(val);
      }, 500);
    },
  },
  async mounted() {
    if (this.is_editing) {
      let { data: promotion } = await get_promotion_by_id(this.$route.query.id);
      this.promotion = { ...promotion };
      await this.search_shops();
    }
  },
  methods: {
    ...mapActions("SuperAdminStore", ["get_brands"]),

    async search_shops(name) {
      this.loading_shops = true;
      try {
        const params = { name };
        if (this.is_community_admin) {
          params.brand_id = this.brands[0].id;
        }
        const { data: shops } = await get_shops(params);
        this.shops = shops;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading_shops = false;
      }
    },
    async upsert_promotion() {
      this.processing_promotion = true;
      try {
        let promotion_in = { ...this.promotion };
        promotion_in.brand_id = this.selected_brand.id;

        await upsert_promotion(promotion_in);
        this.$router.replace({
          name: "admin-brands-id-promotions",
          params: { brand_id: this.selected_brand.id },
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.loading_shops = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bordered {
  background-color: white;
  border-radius: 10px;
}
.light-grey-text {
  color: #00000099;
}
.promotion-form {
  width: 50vw;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    width: unset;
  }

  .disabled {
    cursor: not-allowed;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      inset: 0;
      background-color: rgba(238, 238, 238, 0.3);
      z-index: 10;
    }
  }

  .img-preview-box {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    .image-preview {
      margin: auto;
      height: 56px;
      width: 200px;
      background-position: center center;
      background-repeat: initial;
      background-size: contain, auto;

      &.--home-img {
        width: 192px;
        height: 108px;
      }

      &.--favicon {
        background-repeat: no-repeat;
        background-size: contain, auto;
      }
    }
  }
}
</style>
